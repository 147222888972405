<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';

export default {
  props: {
    title: { dataType: String },
    placeholder: { dataType: String },
    disabled: { dataType: Boolean, default: false },
    readonly: { dataType: Boolean, default: false },
  },
  components: { DatePicker },
  data() {
    return {
      date: null,
    };
  },
  methods: {
    onSelected(date) {
      this.$emit('selected', date);
    },
  }
};
</script>

<style>
.today {
  background-color: #f8e3ea;
}
.mx-icon-calendar {
  cursor: pointer;
}
.mx-input {
  border-radius: 5px !important;
  font-size: 14px !important;
  text-align: center;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  border-color: #d1d5db !important;
  min-height: 40px;
}
</style>
<template>
  <div
    :disabled="disabled"
    class="col-span-1 md:col-span-2 pt-4 mt-[0.175rem]"
  >
    <legend class="block">
      <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
        {{ title }}
      </span>
    </legend>
    <date-picker
      class="mt-2 w-full"
      v-model="date"
      type="datetime"
      format="DD.MM.YYYY HH:mm"
      @input="onSelected(date)"
      :placeholder="placeholder"
    ></date-picker>
  </div>
</template>

