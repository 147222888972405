
<script >
import Capitalize from '../../lib/capitalize'
import Communities from '../appeal/_community.vue';
import Messanger from '../appeal/_messanger.vue';
import Assistance from '../appeal/_assistance.vue';
import DateTime from '../appeal/_datatime.vue';
import ModalInfoVue from '../ModalInfo.vue';
import refresh from '@/lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';

export default {
  components: { Communities, Messanger, Assistance, DateTime, ModalInfoVue },
  data() {
    return {
      id: '',
      appeal: [],
      name: '',
      community_id: '',
      region_id: '',
      phone: '',
      communiction: '',
      assistans_type: '',
      description: '',
      children_help: false,
      date_communication: '',
      creator: '',
      errNumberPhone: false,
      status_id: 1,
      message: '',
      disabled: false
    }
  },
  methods: {
    async getAppeal(id) {
      try {
        const response = await fetchWithAuth(`/api/appeals/${id}`, {
          method: 'GET',
        });
        if (response.ok) {
          this.appeal = await response.json();
          this.description = this.appeal.description || '';
          this.community_id = this.appeal.community_id || '';
          this.date_communication = this.appeal.date_communication || '';
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login');
      }
    },
    async updateAppeal(id, data) {
      const response = await fetchWithAuth(`/api/appeals/${id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
      });
      try {
        if (response.ok) {
          this.appeal = await response.json();
          this.message = 'Замовлення збережено';
          this.showModal();
        } else {
          this.appeal = await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    },
    submit() {

      this.data = {
        name: this.name,
        community_id: this.communityId,
        region_id: this.region_id,
        phone: this.formatPhoneNumber(this.phone),
        communiction: this.communiction,
        assistans_type: this.assistans_type,
        description: this.description,
        children_help: this.children_help,
        date_communication: this.date_communication,
        status_id: this.status_id,
        creator: this.name,
      };

      this.data = Object.fromEntries(
        Object.entries(this.data).filter(([, value]) => value !== '' && value !== null && value !== undefined)
      );
      this.updateAppeal(this.id, this.data);
    },

    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.name = Capitalize(filteredInput);
    },

    filterText(input) {
      let filteredText = '';
      const regex = new RegExp(/^[!?.,а-яА-ЯёЁіїґє'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredText += char;
        }
      }
      this.description = Capitalize(filteredText);
    },

    formatPhoneNumber(phone) {

      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },

    onValidatePhoneNumber(phoneNumber) {

      if (phoneNumber.startsWith('+38(00') || phoneNumber.length != 17) {
        return this.errNumberPhone = true;
      } else {
        return this.errNumberPhone = false;
      }
    },

    onMessangerSelected(value) {

      this.communiction = value;
    },
    onAssistanceSelected(value) {

      this.assistans_type = value;
    },
    onCommunitySelected(value) {
      this.community_id = value;
      console.log(value);

    },
    onDateTimeSelected(value) {

      this.date_communication = value;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal() {
      window.location.reload();
    },
    hideListCommunity() {
      this.$refs.community.onClose();
    }
  },
  watch: {
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      const segments = window.location.href.split('/');
      this.id = segments.pop();
      this.getAppeal(this.id);
    })
  }
}
</script>

<template>
  <div class="px-4 lg:px-4">
    <ModalInfoVue
      ref="modalInfo"
      infoText="Замовлення оновлено"
      @close="hideModal"
    />
    <div
      class="flex justify-end"
      v-if="appeal.status_id ==1"
    >
      <button
        class="bg-[#4294ff] hover:bg-blue-600 focus:outline-none max-w-[180px] text-[13px] rounded-lg mb-3 px-6 py-2 text-white font-semibold shadow"
        @click.prevent="submit"
      >Зберегти</button>
    </div>
    <form
      action="#"
      method="POST"
      class="mx-auto mt-3"
    >
      <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
        <div class="">
          <label class="block">
            <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
              Ім'я
            </span>
          </label>
          <div class="mt-2.5">
            <input
              type="text"
              v-model="appeal.name"
              @keyup="filterInput(appeal.name)"
              placeholder="Ім'я"
              required
              :disabled="appeal.status_id !=1 ? true : false"
              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="">
          <label class="block">
            <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
              Телефон
            </span>
          </label>
          <div class="mt-2.5">
            <input
              v-mask="'+38(0##)###-##-##'"
              placeholder="Телефон"
              v-model="appeal.phone"
              id="phone"
              required
              :disabled="appeal.status_id !=1 ? true : false"
              @blur="onValidatePhoneNumber(phone)"
              type="tel"
              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              :class="errNumberPhone ?'border-2 border-rose-500 text-red-600':''"
            />
            <p
              v-if="errNumberPhone"
              class="text-red-500 text-[12px] transition-all duration-200"
            >Помилка в наборі телефона*</p>
          </div>
        </div>

        <div class="pt-4">
          <Communities
            v-model="communityId"
            :readonly="appeal.status_id !=1 ? true : false"
            :disabled="appeal.status_id !=1 ? true : false"
            @selected="onCommunitySelected"
          />
        </div>
        <div>
          <DateTime
            @selected="onDateTimeSelected"
            title="Бажаний дата/час зустрічи"
            placeholder="Оберіть дату та час"
            :disabled="appeal.status_id !=1 ? true : false"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">
        <div class="">
          <label
            for="community_name"
            class="block text-sm font-medium leading-6 text-gray-900"
          >Громада</label>
          <div class="mt-2">
            <input
              type="text"
              readonly
              disabled
              v-if="appeal.community"
              v-model="appeal.community.name"
              id="community_name"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="">
          <label
            for="adminCenter"
            class="block text-sm font-medium leading-6 text-gray-900"
          >Амін центр</label>
          <div class="mt-2">
            <input
              type="text"
              readonly
              disabled
              v-if="appeal.community"
              v-model="appeal.community.adminCenter"
              id="adminCenter"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="">
          <label
            for="region"
            class="block text-sm font-medium leading-6 text-gray-900"
          >Область</label>
          <div class="mt-2">
            <input
              type="text"
              readonly
              disabled
              v-if="appeal.community"
              v-model="appeal.community.region"
              id="region"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="">
          <label
            for="region"
            class="block text-sm font-medium leading-6 text-gray-900"
          >Звсіб комунікаціі</label>
          <div class="mt-2">
            <input
              type="text"
              readonly
              disabled
              v-if="appeal"
              v-model="appeal.communiction"
              id="region"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="">
          <label
            for="region"
            class="block text-sm font-medium leading-6 text-gray-900"
          >Допомога</label>
          <div class="mt-2">
            <input
              type="text"
              readonly
              disabled
              v-if="appeal"
              v-model="appeal.assistans_type"
              id="region"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="">
          <label
            for="region"
            class="block text-sm font-medium leading-6 text-gray-900"
          >Допомога дітям</label>
          <div class="mt-2">
            <input
              type="text"
              readonly
              disabled
              v-if="appeal"
              :value="appeal.children_help ? 'Так': 'Ні'"
              id="region"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="col-span-1">
          <Messanger
            @checked="onMessangerSelected"
            :readonly="appeal.status_id !=1 ? true : false"
            :disabled="appeal.status_id !=1 ? true : false"
          />
        </div>
        <div class="col-span-1">
          <Assistance
            @checked="onAssistanceSelected"
            :readonly="appeal.status_id !=1 ? true : false"
            :disabled="appeal.status_id !=1 ? true : false"
          />
        </div>
        <div class="col-span-1 mt-4">
          <div class="border rounded border-stone-200 mt-2 space-y-4 bg-white p-4">
            <div class="flex items-center gap-x-3">
              <input
                id="no"
                name="children_help"
                v-model="children_help"
                type="radio"
                :disabled="appeal.status_id !=1 ? true : false"
                class=" cursor-pointer h-5 w-5 border-pink-200 text-pink-300 focus:ring-white"
              >
              <label
                for="no"
                class=" cursor-pointer block text-sm font-medium leading-6 text-gray-900"
              >Ні</label>
            </div>
            <div class="flex items-center gap-x-3">
              <input
                id="yes"
                name="children_help"
                v-model="children_help"
                type="radio"
                :disabled="appeal.status_id !=1 ? true : false"
                class="h-5 w-5 cursor-pointer border-pink-200 text-pink-300 focus:ring-white"
              >
              <label
                for="yes"
                class="cursor-pointer block text-sm font-medium leading-6 text-gray-900"
              >Так</label>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3 mt-4">
          <label class="block">
            <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
              Опис проблеми
            </span>
          </label>
          <div class="mt-2.5">
            <textarea
              rows="4"
              v-model="description"
              @keyup="filterText(description)"
              placeholder=""
              :disabled="appeal.status_id !=1 ? true : false"
              :readonly="appeal.status_id !=1 ? true : false"
              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>

        </div>

      </div>
    </form>

  </div>
</template>

<style scoped>
* {
  font-family: 'Roboto', sans-serif;
}

.warning {
  /* border: 1px solid #d7d3d3; */
  padding: 20px 10px;
  border-radius: 8px;
}
.enough {
  position: absolute;
  font-size: 275px;
  transform: rotate(-45deg);
  left: 8%;
  top: 40dvh;
  opacity: 0.07;
  letter-spacing: 1rem;
  z-index: 1;
  color: #f27272;
  pointer-events: none;
}
</style>